<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editCategory') }}</h5>
                <div class="p-field p-grid" v-show="this.isEdit()">
                    <label for="sportId" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportId" type="text" v-model="category.id" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="sportName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportName" type="text" v-model="category.name" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.displayName') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="displayName" type="text" v-model="category.displayName" />
                    </div>
                </div>
                <div class="p-field p-grid" v-show="!this.isEdit()">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.sport') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="sortOrder" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sortOrder" type="text" v-model="category.sortOrder" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <transition-group name="p-message" tag="div" v-show="showError">
                    <Message :key="errorKeyCount" :severity="'error'">{{ errorText }}</Message>
                </transition-group>
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { store } from '../../store/index';
export default {
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            category: {},
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'categories',
            sports: [],
            sportsModel: {},
            errorKeyCount: 0,
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter']('sport');
        },
    },
    watch: {
        sportsModel: function () {
            this.category.sportId = this.sportsModel.id;
        },
    },
    mounted() {
        if (this.isEdit()) {
            this.SportsSettingsApi.getCategory(this.$route.params.id)
                .then((response) => {
                    this.category = response.data;
                })
                .catch((error) => {
                    this.errorText = error;
                    this.showError = true;
                });
        } else {
            this.SportsSettingsApi.getAllSports().then((response) => {
                this.sports = response.data;
            });
        }
    },
    methods: {
        submitForm() {
            this.disableSubmit = true;
            this.showError = false;
            this.errorText = '';
            if (
                this.notNullOrEmptyObject(this.category) &&
                this.notNullOrEmptyField(this.category.name) &&
                this.notNullOrEmptyField(this.category.displayName) &&
                this.notNullOrEmptyField(this.category.sortOrder) &&
                this.notNullOrEmptyField(this.category.sportId)
            ) {
                this.SportsSettingsApi.updateCategory(this.category)
                    .then(() => {
                        this.goto(this.backRoute);
                    })
                    .catch((error) => {
                        this.disableSubmit = false;
                        this.errorText = error;
                        this.showError = true;
                        this.errorKeyCount++;
                    });
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
    },
};
</script>
